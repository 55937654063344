import React from "react";
import { Helmet } from "react-helmet";
import Form from "../components/Contact/form";
import contact from "./../assets/images/contact.svg";

function ContactTest() {
  return (
    <div className="h-full">
      <Helmet>
        <title>Contactez-nous</title>
        <meta
          name="description"
          content="Page de contact de notre site. Remplissez le formulaire pour nous envoyer un message."
        />
        <meta
          name="keywords"
          content="contact, formulaire, entreprise, message"
        />
        <meta name="author" content="Tech' Talents" />
      </Helmet>

      <h2 className="text-2xl p-6 uppercase font-bold text-textColor text-center">
        Contactez-nous
      </h2>
      <div className="flex flex-col md:flex-row justify-evenly ">
        <div className="flex items-center flex-col text-center">
          <div className="">
            <img
              className="max-w-[40rem] w-full h-auto object-cover"
              src={contact}
              alt="Contact illustration"
            />
          </div>
        </div>
        <div className="justify-center items-center">
          <Form />
        </div>
      </div>
    </div>
  );
}

export default ContactTest;
