import React from "react";
import emailjs from "emailjs-com";
import { Link } from "react-router-dom";

function Form() {
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_sdy05rj",
        "template_4n8gatk",
        e.target,
        "l_Wwp8twOFllbB-f9",
        {
          reply_to: e.target.floating_email.value,
        }
      )
      .then(
        (result) => {
          console.log(result.text);
          // Ici, vous pouvez gérer la réussite de l'envoi
          alert("Message envoyé avec succès !");
        },
        (error) => {
          console.log(error.text);
          // Ici, vous pouvez gérer l'échec de l'envoi
          alert("Erreur lors de l'envoi du message. Veuillez réessayer.");
        }
      );
  };

  return (
    <div className="flex justify-center items-center m-4">
      <form
        className="flex flex-col p-4 w-full max-w-4xl md:w-3/4 sm:w-full"
        onSubmit={sendEmail}
      >
        <div className="flex flex-col md:flex-row gap-10">
          <div className="relative z-0 group w-full">
            <input
              type="text"
              name="floating_last_name"
              id="floating_last_name"
              className="block mt-4 px-0 w-full text-base text-backgroundColor bg-transparent border-0 border-b-2 border-backgroundColor appearance-none focus:outline-none focus:ring-0 peer"
              placeholder=" "
              required
            />
            <label
              htmlFor="floating_last_name"
              className="peer-focus:font-medium absolute text-base text-textColor duration-300 transform -translate-y-8 scale-75 top-4 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-backgroundColor peer-focus:backgroundColor peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-light"
            >
              Nom
            </label>
          </div>
          <div className="relative z-0 w-full mb-10 group flex flex-col">
            <input
              type="text"
              name="floating_first_name"
              id="floating_first_name"
              className="block mt-4 px-0 w-full text-base text-backgroundColor bg-transparent border-0 border-b-2 border-backgroundColor appearance-none focus:outline-none focus:ring-0 peer"
              placeholder=" "
              required
            />
            <label
              htmlFor="floating_first_name"
              className="peer-focus:font-medium absolute text-base text-textColor duration-300 transform -translate-y-8 scale-75 top-4 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-backgroundColor peer-focus:backgroundColor peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-light"
            >
              Prénom
            </label>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-10">
          <div className="relative z-0 w-full group flex flex-col">
            <input
              type="email"
              name="floating_email"
              aria-describedby="helper-text-explanation"
              id="floating_email"
              className="block mt-4 px-0 w-full text-base text-backgroundColor bg-transparent border-0 border-b-2 border-backgroundColor appearance-none focus:outline-none focus:ring-0 peer"
              placeholder=""
              required
            />
            <label
              htmlFor="floating_email"
              className="peer-focus:font-medium absolute text-base text-textColor duration-300 transform -translate-y-8 scale-75 top-4 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-backgroundColor peer-focus:backgroundColor peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-light"
            >
              E-mail
            </label>
          </div>
          <div className="relative z-0 w-full mb-10 group flex flex-col">
            <input
              type="text"
              name="floating_phone"
              id="floating_phone"
              className="block mt-4 px-0 w-full text-base text-backgroundColor bg-transparent border-0 border-b-2 border-backgroundColor appearance-none focus:outline-none focus:ring-0 peer"
              placeholder=""
            />
            <label
              htmlFor="floating_phone"
              className="peer-focus:font-medium absolute text-base text-textColor duration-300 transform -translate-y-8 scale-75 top-4 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-backgroundColor peer-focus:backgroundColor peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-light"
            >
              Téléphone
            </label>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-10">
          <div className="relative z-0 w-full group flex flex-col">
            <input
              type="text"
              name="floating_company"
              id="floating_company"
              className="block px-0 w-full text-base text-backgroundColor bg-transparent border-0 border-b-2 border-backgroundColor appearance-none focus:outline-none focus:ring-0 peer"
              placeholder=""
            />
            <label
              htmlFor="floating_company"
              className="peer-focus:font-medium absolute text-base text-textColor duration-300 transform -translate-y-8 scale-75 top-0 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-backgroundColor peer-focus:backgroundColor peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-light"
            >
              Société
            </label>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-10 pt-6">
          <div className="relative z-0 w-full md:col-span-2 group flex flex-col mb-10">
            <label
              htmlFor="demands"
              name="demands"
              className="block mb-4 text-base font-light text-textColor"
            >
              Votre demande concerne :
            </label>
            <select
              id="demands"
              name="demands"
              className="bg-primaryColor w-full border-2 border-backgroundColor text-backgroundColor text-base focus:ring-backgroundColor focus:border-backgroundColor block py-2.5"
            >
              <option>...</option>
              <option>Salesforce</option>
              <option>Besoin IT</option>
              <option>Régie</option>
              <option>Autre</option>
            </select>
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:gap-6">
          <div className="relative z-0 w-full md:col-span-2 group flex flex-col">
            <textarea
              id="message"
              name="message"
              rows="4"
              className="block p-2.5 w-full text-base text-textColor bg-transparent border-b-2 border-textColor focus:ring-textColor"
              placeholder="Laissez nous un message  ..."
            ></textarea>
          </div>
        </div>
        <div className="flex flex-col md:gap-6 items-center text-left">
          <div className="flex flex-col md:gap-6 items-start">
            <div className="relative z-0 w-full mb-8 group flex justify-start items-center gap-4 p-4 rounded-lg">
              <input
                id="terms"
                type="checkbox"
                value=""
                className="w-6 h-8 border border-textColor rounded bg-gray-50 focus:ring-3 focus:ring-navColor"
                required
              />
              <label
                htmlFor="terms"
                className="font-medium text-textColor text-left text-sm text-balance"
              >
                J’atteste que les données renseignées sont exactes et j’accepte
                que ces données soient utilisées pour que Tech' Talents puisse{" "}
                <Link
                  to="politiques-de-confidentialité"
                  className="text-backgroundColor font-bold hover:underline text-sm"
                >
                  me contacter *
                </Link>
              </label>
            </div>
          </div>
        </div>
        <div className="relative z-0 w-full mb-8 group flex flex-col">
          <button
            type="submit"
            className="px-8 py-2 duration-700 mx-auto text-backgroundColor border-2 border-backgroundColor hover:text-primaryColor hover:bg-backgroundColor hover:border-primaryColor mr-auto text-base text-center"
          >
            Envoyer
          </button>
        </div>
        <div className="flex justify-center items-center p-4 mr-auto">
          <p className="text-textColor text-sm">
            * Pour connaitre et exercer vos droits, notamment de retrait de
            votre consentement à l’utilisation des données collectées par ce
            formulaire, veuillez consulter notre politique de protection des
            données personnelles.
          </p>
        </div>
      </form>
    </div>
  );
}

export default Form;
