import React, { lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Homepage/header";
import Cards from "../components/Homepage/Cards/cards";
import { Link } from "react-router-dom";

const Responsive = lazy(() =>
  import("../components/Homepage/PartnerLogosSlider")
);
const SportSection = lazy(() => import("../components/Homepage/SportSection"));
const Slider = lazy(() => import("../components/Homepage/Slider"));
const Spline = lazy(() => import("../components/Homepage/spline"));

function Techtalents() {
  return (
    <section>
      <Helmet>
        <title>Tech Talents - Votre intégrateur Salesforce écologique</title>
        <meta
          name="description"
          content="Explorez nos services en Salesforce écologique et projets IT chez Tech Talents. Découvrez comment notre expertise peut accélérer votre croissance."
        />
        <meta
          property="og:title"
          content="Tech Talents - Votre intégrateur Salesforce écologique"
        />
        <meta
          property="og:description"
          content="Explorez nos services en Salesforce écologique et projets IT chez Tech Talents."
        />
        <meta
          property="og:image"
          content="https://tech-talents.fr/images/logo.png"
        />
        <meta property="og:url" content="https://tech-talents.fr" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Header />
      <div className="pb-10 mx-8">
        <h2 className="flex p-4 xs:text-lg lg:text-xl text-textColor font-extrabold xs:justify-center xs:text-center xl:justify-normal">
          Notre expertise
        </h2>
        <Cards />
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <div className="m-8">
          <SportSection />
        </div>
        <div className="grid xs:grid-cols-1 lg:grid-cols-2 place-content-center">
          <div className="xs:mx-3 lg:mx-8">
            <h2 className="flex p-4 xs:text-lg lg:text-xl text-textColor font-extrabold xs:justify-center xs:text-center xl:justify-normal">
              Ils nous font confiance
            </h2>
            <Responsive />
          </div>
          <div className="xs:mx-3 lg:mx-10">
            <h2 className="flex p-4 mb-3 xs:text-lg lg:text-xl text-textColor font-extrabold xs:justify-center xs:text-center xl:justify-normal">
              Nos clients témoignent
            </h2>
            <Slider />
          </div>
        </div>

        <div className="m-8">
          <div className="grid xs:grid-cols-1 lg:grid-cols-2 text-center items-center">
            <div className="text-center">
              <h2 className="mb-4 flex xs:text-lg text-xl p-4 text-textColor font-extrabold xs:justify-center lg:justify-start">
                Marchons ensemble vers le chemin de la réussite
              </h2>
              <Link
                to="contactez-nous"
                className="border inline-flex text-base text-center items-center px-3 py-2 shadow-xl text-primaryColor bg-textColor hover:bg-primaryColor hover:text-textColor focus:ring-4 focus:outline-none focus:ring-secondaryColor mt-5 mr-1 hover:border hover:border-textColor rounded-md cursor-pointer"
              >
                Demandez un devis personnalisé
                <svg
                  className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                  aria-hidden="true"
                  aria-label="Demandez un devis personalisé"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </Link>
            </div>
            <div className="w-full">
              <Spline />
            </div>
          </div>
        </div>
      </Suspense>
    </section>
  );
}

export default Techtalents;
